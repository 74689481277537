"use client"
import React           from 'react'
import {graphql, Link} from 'gatsby'

import Layout                                                            from '../components/shared/layout'
import {Button, Container, createStyles, Grid, Paper, Theme, Typography} from '@material-ui/core'
import theme                                                             from '../theme'
import {makeStyles}                                                      from '@material-ui/core/styles'
import MailChimpSignupForm                                               from '../components/mailchimp'
import Shattered                                                         from '../assets/textures/shattered.png'
import EggShell                                                          from '../assets/textures/egg-shell.png'
import BackgroundImageCompat                                             from "../components/BackgroundImageCompat";
import {BgImage}                                                         from "gbimage-bridge";

export const query = graphql`
    query {
        site {
            buildTime
        }
        sanitySiteSettings {
            siteMedia {
                homePageBackground {
                    alt
                    asset {
                        gatsbyImageData
                    }
                }
            }
        }

    }

`

const useStyles = makeStyles((theme: Theme) => createStyles({
    bannerTextContainer: {
        padding: '8rem 0',
        width: '100%',
        color: '#FFF',
        top: '10%',
        [theme.breakpoints.down('sm')]: {
            padding: '4rem 0'
        }
    },
    textBackground: {
        backgroundColor: 'hsla(0, 0%, 0%, .4)',
        padding: '2rem 0'
    },
    headerText: {
        padding: '0rem 4rem',
        fontWeight: 600,
        fontFamily: 'Cormorant, serif',
        outline: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem',
            padding: '3rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8rem',
            padding: '1.8rem'
        }
    },
    headerContainer: {
        // maxHeight: '800px',
    },
    callToActionContainer: {
        zIndex: 2,
        backgroundColor: '#E3E0DD',
        backgroundImage: `url(${Shattered})`,
        padding: '60px'

    },
    callToAction: {
        textAlign: 'center',
        color: '#B5A6A1',
        padding: theme.spacing(5),
        '& p': {
            color: '#3A3737'
        }
    },
    ctaLink: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
        backgroundColor: '#3A3737',
        backgroundImage: `url(${EggShell})`,
        color: '#F4F3EE',
        marginBottom: '1rem',
        minWidth: '125px',
        transition: '0.25s',
        '&:hover': {
            backgroundColor: '#4D4A4A'
        }
    },
    aboutUsContainer: {
        background: '#FFF',
        color: '#7B7675',
        textAlign: 'center',
        padding: theme.spacing(12),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4)
        }
    },
    lead: {
        fontWeight: 500,
        textAlign: 'left'
    },
    subLead: {
        fontWeight: 300,
        textAlign: 'left'
    },
    meetPeopleLink: {
        textDecoration: 'none',
        textAlign: 'left',
        color: '#7B7675',
        '&:hover': {
            color: '#302E2E'
        }
    },
    signUpContainer: {
        padding: '5% 0 5% 0',
        background: '#1C1818',
        color: '#B5A6A1'
    },
    textFieldRoot: {
        backgroundColor: '#FFF',
        color: '#000',
        fontFamily: 'Calibre',
        borderRadius: '5px'
    },
    submitButton: {
        backgroundColor: '#7B7675'
    },
    homeImage: {
        width: '100%',
        minHeight: '300px'
    },
    homePageLayout: {
        // display: 'grid',
        // gridTemplateRows: 'minmax(800px, 1fr) 1fr 1fr 1fr'
    }

}), {index: 1})

const HomePage = (props: any) => {

    const buildTime = `${new Date(props.data.site.buildTime).toLocaleString()} AEST`

    const classes = useStyles(theme)

    return (
        <Layout buildTime={buildTime} helmetData={{
            pageTitle: 'Home',
            metaDescription: 'LEVEL 22 CHAMBERS is a leading commercial and administrative set based in the heart of Sydney’s legal precinct',
            slug: ''
        }} backgroundImage={''} backgroundColor=''>
            <section className={classes.homePageLayout}>
                <section className={classes.headerContainer}>
                    <BgImage className={classes.homeImage} fadeIn={true} durationFadeIn={1000}
                                           backgroundColor={'#FFF'}
                                           image={{
                                               layout: 'fullWidth',
                                               width: 2000,
                                               height: 1063,
                                               images: props.data.sanitySiteSettings.siteMedia.homePageBackground.asset.gatsbyImageData.images                                           }}>

                        <section className={classes.bannerTextContainer}>
                            <div className={classes.textBackground}>
                                <Typography className={classes.headerText} variant={'h2'} component={'h2'}>
                                    We act on what matters to you,<br/>
                                    in advocacy, compliance, and advice.<br/>
                                    Let us help you with our<br/>
                                    accessible legal expertise
                                    &#8212;
                                </Typography>
                            </div>
                        </section>
                    </BgImage>
                </section>
                <Paper>
                    <section className={classes.callToActionContainer}>
                        <Grid justify="space-around" alignContent="center" spacing={6} container>
                            <Grid xs={12} sm={5} md={3} className={classes.callToAction} item>
                                <Button size={'large'} className={classes.ctaLink} href="/barristers">
                                    Barristers
                                </Button>
                                <Typography variant={'body1'} component={'p'}>
                                    Find the barrister suited<br/> to your matter
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={5} md={3} className={classes.callToAction} item>
                                <Button size={'large'} href="/events" className={classes.ctaLink}>
                                    Events + Seminars
                                </Button>
                                <Typography variant={'body1'} component={'p'}>
                                    Professional development <br/>events and seminars
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={5} md={3} className={classes.callToAction} item>
                                <Button size={'large'} href="/news" className={classes.ctaLink}>
                                    News
                                </Button>
                                <Typography variant={'body1'} component={'p'}>
                                    Our members' achievements <br/>and other happenings
                                </Typography>
                            </Grid>
                        </Grid>
                    </section>
                </Paper>
                <section className={classes.aboutUsContainer}>
                    <Container maxWidth={'md'}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography className={classes.lead} variant={'h4'} component={'h1'}>
                                    LEVEL 22 CHAMBERS is a leading commercial and administrative set based
                                    in the heart of Sydney’s legal precinct
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>

                                <Typography className={classes.subLead} variant={'h6'} component={'p'}>
                                    Our barristers provide expert legal and strategic assistance in a wide range of
                                    administrative law, class action, commercial law, defamation, employment and
                                    industrial law, government and governance, intellectual property and communications
                                    law, public law, and criminal law matters.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.subLead} variant={'h6'} component={'p'}>
                                    Ask our <Link style={{color: 'goldenrod'}} to={'/clerks'}>clerking team</Link> to
                                    assist you to find the right
                                    barrister for your matter.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.subLead} variant={'h6'} component={'p'}>
                                    Our 36 barristers act as advocates, advisers, arbitrators and mediators for
                                    Australian and international clients. Our demonstrated track record of excellence
                                    shows our commitment to the delivery of practical legal assistance and advice.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Link className={classes.meetPeopleLink} to={'/barristers'}>
                                    <Typography variant={'h6'} component={'p'}>
                                        MEET OUR BARRISTERS {'>'}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>

                    </Container>
                </section>
                <section className={classes.signUpContainer}>
                    <Container maxWidth={'md'}>
                        <MailChimpSignupForm
                            mailChimpUrl={'http://eepurl.com/gU8u0j'}/>
                    </Container>
                </section>
            </section>
        </Layout>
    )
}

export default HomePage
