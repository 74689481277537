import React, {useState}                               from 'react'
import {Button, createStyles, Grid, Theme, Typography} from '@material-ui/core'
import {makeStyles}                                    from '@material-ui/core/styles'
import theme                                           from '../theme'
import MailChimpSubscribe                              from 'react-mailchimp-subscribe'

type MailChimpSignupFormProps = {
  children?: React.ReactNode
  mailChimpUrl: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  textFieldRoot: {
    backgroundColor: '#FFF',
    color: '#000',
    fontFamily: 'Calibre',
    borderRadius: '5px'
  },
  submitButton: {
    backgroundColor: '#7B7675',
    color: '#FFF',
    minWidth: '300px',
    transition: '0.5s',
    fontWeight: 600,
    '&:hover': {
      background: '#EEEEEE',
      color: 'goldenrod'
    },
    [theme.breakpoints.down('xs')]:{
      marginBottom: '1em'
    }
  },
  registerText: {
     [theme.breakpoints.down('xs')]: {
       textAlign: 'center'
     }
  }

}), {index: 1})
const MailChimpSignupForm = (props: MailChimpSignupFormProps) => {
  const classes = useStyles(theme)
  const {mailChimpUrl} = props

  return (
    <MailChimpSubscribe url={mailChimpUrl} render={({subscribe, status, message}) => (
      <form>
        <Grid container justify="center" alignContent="center" spacing={4}>
          <Grid container alignContent="center" item xs={12} sm={6}>
            <Typography className={classes.registerText} variant={'h4'} component={'p'}>
              Register for our monthly enewsletter to stay up to date with news, events and professional
              development opportunities.
            </Typography>
          </Grid>
          <Grid container item justify={'center'} alignItems={'center'} xs={12} sm={6}>
            <Button target={'_blank'} size={'large'} className={classes.submitButton} variant={'outlined'} href={mailChimpUrl}>
               Register Now
            </Button>
          </Grid>
        </Grid>
      </form>
    )}/>
  )
}

export default MailChimpSignupForm
